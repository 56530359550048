import React from "react"
import Button from "../atoms/Button"
import * as styles from "./FilterButtons.module.scss"

type FilterButtonsProps = { buttons: { label: string; id: string }[]; selected: string; onChange: (id: string) => void }

const FilterButtons = (props: FilterButtonsProps) => {
  // console.log('Rendering FilterButtons', props)
  return (
    <div className={styles.container}>
      {props.buttons.map(btn => {
        return (
          <Button
            key={btn.id}
            priority={btn.id === props.selected ? "primary" : "secondary"}
            label={btn.label}
            onClick={() => (props.selected === btn.id ? props.onChange("") : props.onChange(btn.id))}
          />
        )
      })}
    </div>
  )
}

export default FilterButtons
