import { Link, graphql, useStaticQuery } from "gatsby"
import React, { useContext, useState } from "react"
import { HiExternalLink } from "react-icons/hi"
import { MdOutlineFileDownload } from "react-icons/md"
import ExternalLink from "../../../../components/atoms/ExternalLink"
import FilterButtons from "../../../../components/molecules/FilterButtons"
import { findDeepestCategory } from "../../../../components/organisms/product/utils"
import { PageContext } from "../../../../context/PageContext"
import useDatoMicrocopy from "../../../../hooks/useDatoMicrocopy"
import useProductCategoryStructure from "../../../../hooks/useProductCategoryStructure"
import useProductPath from "../../../../hooks/useProductPath"
import ResponsiveTable, { ResponsiveTableProps } from "../../../molecules/ResponsiveTable"
// import * as styles from "./DatoTechnicalFilesList.module.scss"

type DatoTechnicalFilesListProps = {}

/** Listataan kaikki tuotteet joilla on CAD-malleja, ja näytetään niille tuotteille myös tekniset piirrustukset ja manuaalit. Koska näin toivottiin. */

const DatoTechnicalFilesList = (props: DatoTechnicalFilesListProps) => {
  //console.log("Rendering DatoTechnicalFilesList", props)
  const [filter, setFilter] = useState("")
  const data: Queries.HarviaProductModelsQuery = useStaticQuery(graphql`
    query HarviaProductModels {
      allHarviaProduct(
        filter: { media_technicalModels: { elemMatch: { id: { glob: "*" } } } } # Vain tuotteet joilla on teknisiä malleja
        sort: { fields: name, order: ASC }
      ) {
        totalCount
        nodes {
          identifier
          name
          categories
          locale
          media_technicalModels {
            name
            link
            type
          }
          media_technicalImages {
            name
            type
            link
          }
          media_manuals {
            name
            link
            type
          }
        }
      }
    }
  `)
  const { localePim, locale } = useContext(PageContext)
  const getProductPath = useProductPath()

  const t = useDatoMicrocopy("product", locale)

  const structure = useProductCategoryStructure()
  const structureLocale = localePim.replace("-", "_")
  const structureFallbackLocale = "en_US"

  /** Filtterit kategorioittain */
  const categories: { [code: string]: string } = data.allHarviaProduct.nodes.reduce((prev, product) => {
    const cat = findDeepestCategory(structure.subCategories, product.categories as string[])
    if (!cat) return prev

    const label =
      prev[cat.code] ||
      cat.labels.find(lbl => lbl.name === structureLocale)?.value ||
      cat.labels.find(lbl => lbl.name === structureFallbackLocale)?.value ||
      "Other"

    return { ...prev, [cat.code]: label }
  }, {})

  const columns = [
    t("product", "Product"),
    t("sku", "Sku"),
    t("filesTechModels", "Technical models"),
    t("filesTechImages", "Technical images"),
    t("filesManuals", "User manuals"),
  ]

  const rows: ResponsiveTableProps["rows"] = data.allHarviaProduct.nodes
    .filter(product => product.locale === localePim && (!filter || (filter && product.categories?.includes(filter))))
    .map(product => {
      const column: ResponsiveTableProps["rows"][0] = columns.map((col, columnIndex) => {
        switch (columnIndex) {
          //case "Product":
          case 0:
            return [
              <Link to={getProductPath(product.identifier)}>
                {product.name?.trim()}&nbsp;
                <HiExternalLink />
              </Link>,
            ]

          //case "Sku":
          case 1:
            return [<span>{product.identifier}</span>]

          //case "CAD-files":
          case 2:
            return (
              product.media_technicalModels?.map(
                file =>
                  file?.link && (
                    <ExternalLink href={file.link} style={{ whiteSpace: "nowrap" }}>
                      {file.type === "BIM object" ? <>{file.name?.trim()}&nbsp;</> : <>{file.type}&nbsp;</>}
                      <MdOutlineFileDownload />
                    </ExternalLink>
                  )
              ) || []
            )

          //case "Technical Images":
          case 3:
            return (
              product.media_technicalImages?.map(
                file =>
                  file?.link && (
                    <ExternalLink href={file.link}>
                      {file.name?.trim()}&nbsp;
                      <MdOutlineFileDownload />
                    </ExternalLink>
                  )
              ) || []
            )

          //case "Manuals":
          case 4:
            return (
              product.media_manuals?.map(
                file =>
                  file?.link && (
                    <ExternalLink href={file.link}>
                      {file.name?.trim()}&nbsp;
                      <MdOutlineFileDownload />
                    </ExternalLink>
                  )
              ) || []
            )
        }

        return []
      })

      return column
    })

  return (
    <div className="layout-container" style={{ margin: "2em auto" }}>
      {/* <p>{t("sidebarFiltersTitle", "Filter", "productFinder")}:</p> */}
      <FilterButtons
        selected={filter}
        onChange={setFilter}
        buttons={Object.entries(categories).map(([code, cat]) => ({ label: cat, id: code }))}
      />
      <h2>{categories[filter]}</h2>
      <ResponsiveTable columns={columns} rows={rows} />
    </div>
  )
}

export default DatoTechnicalFilesList
