import { graphql, useStaticQuery } from "gatsby"

export type ProductCategoryStructureNode = {
  /** Category name */
  code: string
  /** Category name translations */
  labels: {
    name: "fi_FI" | "en_US" | string
    value: string
  }[]
  subCategories: ProductCategoryStructureNode[]
  containsProductValues: {
    product_family: string[]
    color: string[]
    control_panel_type: string[]
  }
}

const useProductCategoryStructure = (): ProductCategoryStructureNode => {
  const queryRes = useStaticQuery(
    graphql`
      fragment HarviaCategoryStructureFragment on HarviaCategoryStructure {
        code
        labels {
          name
          value
        }
        containsProductValues {
          product_family
          color
          control_panel_type
        }
      }

      query ProductStructureQuery {
        harviaCategoryStructure {
          ...HarviaCategoryStructureFragment
          subCategories {
            ...HarviaCategoryStructureFragment
            subCategories {
              ...HarviaCategoryStructureFragment
              subCategories {
                ...HarviaCategoryStructureFragment
              }
            }
          }
        }
      }
    `
  )

  return queryRes.harviaCategoryStructure
}

export default useProductCategoryStructure
