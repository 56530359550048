import { Product } from "gatsby-source-harvia-pim/types/plugin"
import React from "react"
import type { ProductCategoryStructureNode } from "../../../hooks/useProductCategoryStructure"
import { LocaleArea, isLocaleArea } from "../../../types"
import { ProductAssociation } from "../../pages/productPage/ProductPageContent"
import localePimConfig from "../localeDialog/localePimConfig"

/* 
  
PIM / PRODUCT UTILS

*/

export const apiChannel = "web_pages_harvia_global"
export const apiUrl = `https://pim.harvia.com/rockon-ext/api/v1/products/search/${apiChannel}`

/**
 * Try to parse string to JSON. No errors, only return value if successful.
 * @param str
 */
export const parseJSON = (str: string) => {
  // palauttaa json objektin jos pystyy
  try {
    return JSON.parse(str)
  } catch (e) {
    //return null
  }
}

// export const getFullAssociations = (
//   fullAssociations: Array<ProductDataType> | undefined,
//   product: ProductDataType,
//   association: string
// ) => {
//   if (fullAssociations && product.associations?.[association]?.["products"]) {
//     return fullAssociations.filter(itm => ~product.associations[association].products.indexOf(itm.identifier))
//   }
// }

/**
 * Lisää \&nbsp; - välit ennen tiettyjä sanoja
 * @param text
 * @returns
 */
export const textFormat = (text: string) => {
  if (!text) return ""

  const nonBreakingWords = ["kW", "V", "mm", "m", "cm", "kg", "%"]

  return (
    <>
      {text
        ?.split(/\s/g)
        .map((word, i) =>
          nonBreakingWords.includes(word) ? [<React.Fragment key={i}>&nbsp;</React.Fragment>, word] : ` ${word}`
        )}
    </>
  )
}

export function findDeepestCategory(
  nodes: ProductCategoryStructureNode[],
  categories: string[]
): ProductCategoryStructureNode | undefined {
  if (!categories || !nodes || !nodes.length) return
  let found

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]

    // etsitään löytyykö lapsien joukosta kategorioita
    const child = findDeepestCategory(node.subCategories, categories)
    if (child) {
      found = child
    } else {
      // Jos ei lapsien joukosta löytynyt, niin tarkistetaan onko itsellä
      const cur = categories.find(itm => itm === node.code)
      if (cur) {
        found = node
      }
    }
  }

  return found
}

/**
 * Hakee buildatun tuotteen assosiaatioiden QUANTITY arvot
 * @param data
 * @returns
 */
export function getAssocQuantities(data: ProductAssociation[]) {
  const res: Record<string, string> = {}
  data.forEach((assoc: ProductAssociation) => {
    res[assoc.sku] = assoc.data?.QUANTITY || ""
  })
  return res
}

/**
 * Utility function for labeling products that are not available in current user location area.
 *
 * Uses categories set in {@link localePimConfig} and tries to find a match from Products categories.
 *
 * - If any matches are found, returns `true`.
 * - If none are found, return `false`.
 * - If passed an invalid or unknown location area, returns `undefined`.  This indicates availability cannot be determined.
 *
 * @param product - Product to evaluate for availability
 * @param localeArea - "Location area" to look for
 * @returns `true` if product is available, `false` if it's not or `undefined` if availability cannot be determined from location area
 */
export const isAvailable = (
  product: Pick<Product, "categories" | "identifier">,
  localeArea?: LocaleArea | null
): boolean | undefined => {
  //console.log(product.identifier, product.categories, country)
  if (localeArea === undefined) {
    // we don't have the users preferred "location area"
    return undefined
  }
  if (!isLocaleArea(localeArea)) {
    // we don't have the users preferred "location area"
    return undefined
  }

  const cats = productCategoriesForArea(localeArea)

  return !cats.length || !!cats.find(cat => product.categories.includes(cat))
}

/** Return categories that (ANY of) must be available for selected area/country */
export const productCategoriesForArea = (localeArea: LocaleArea): string[] => {
  if (!localeArea) return []

  return localePimConfig[localeArea] || []
}
