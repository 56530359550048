import React, { ReactNode } from "react"
import * as styles from "./ResponsiveTable.module.scss"

export type ResponsiveTableProps = {
  /** [label] */
  columns: string[]
  /** [row][column][link] */
  rows: ReactNode[][][]
}

const ResponsiveTable = (props: ResponsiveTableProps) => {
  // console.log("Rendering ResponsiveTable", props)
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr>
            {props.columns.map((column, i) => {
              return (
                <td key={i} className={styles.td}>
                  {column}
                </td>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {props.rows.map((row, i) => {
            return (
              <tr key={i}>
                {row.map((col, j) => {
                  return (
                    <td key={j} className={styles.td} data-label={props.columns[j]}>
                      <div className={styles.item}>{col}</div>
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default ResponsiveTable
