import { LocaleArea } from "gatsby-theme-harvia/src/types"

type PimProductCategory = string

type LocalePimConfig = Record<LocaleArea, PimProductCategory[]>

const localePimConfig: LocalePimConfig = {
  // Pohjois-Eurooppa (Pohjoistmaat + Baltian maat), kielet: FI, EN, SV, ET
  "north-europe": [
    "05_harvia_finland_portfolio_main_areas_finland_main",
    "05_harvia_finland_portfolio_european_spareparts",
  ],
  // Eurooppa (kielet: DE, EN)
  europe: [
    "05_harvia_finland_portfolio_main_areas_europe_other",
    "05_harvia_finland_portfolio_CN_main",
    "05_harvia_finland_portfolio_european_spareparts",
    "05_harvia_finland_portfolio_european_hagebau",
  ],

  // Yhdysvallat (kielet: EN-US)
  // alias to "usa", make sure this is the same
  "north-america": [
    "05_harvia_finland_portfolio_usa_all",
    "05_harvia_finland_portfolio_CN_USA",
    "05_harvia_finland_portfolio_north_america_spare_parts",
  ],
  // the effective "north-america"
  // usa: [
  //   "05_harvia_finland_portfolio_usa_all",
  //   "05_harvia_finland_portfolio_CN_USA",
  //   "05_harvia_finland_portfolio_north_america_spare_parts",
  // ],

  // Afrikka (kielet: EN)
  africa: [
    "05_harvia_finland_portfolio_main_areas_europe_other",
    "05_harvia_finland_portfolio_CN_main",
    "05_harvia_finland_portfolio_european_spareparts",
  ],

  // Etelä-Amerikka (kielet: EN)
  "south-america": [
    "05_harvia_finland_portfolio_main_areas_europe_other",
    "05_harvia_finland_portfolio_CN_main",
    "05_harvia_finland_portfolio_european_spareparts",
  ],

  // Kanada (kielet: EN)
  // canada: ["05_harvia_finland_portfolio_canada_all", "05_harvia_finland_portfolio_north_america_spare_parts"],

  // Aasia (kielet: EN, JPN (Japani ohjaa harvia.jp-osoitteeseen)
  asia: ["05_harvia_finland_portfolio_CN_main", "05_harvia_finland_portfolio_european_spareparts"],

  // Lähi-Itä (kielet: EN)
  "middle-east": [
    "05_harvia_finland_portfolio_main_areas_europe_other",
    "05_harvia_finland_portfolio_CN_main",
    "05_harvia_finland_portfolio_european_spareparts",
  ],

  // Oseania (kielet: EN)
  oceania: [
    "05_harvia_finland_portfolio_main_areas_europe_other",
    "05_harvia_finland_portfolio_CN_main",
    "05_harvia_finland_portfolio_european_spareparts",
  ],

  // Globaali: (kielet: EN)
  global: ["05_harvia_finland_portfolio_main_areas_all"],
}

export default localePimConfig
